<template>
<div class="index">


  <section>
    <!-- 场景 -->
    <div class="scenes ">
      <div class="w">
        <div class="title fadeInUp animated wow  mb40">
          <h2>操作流程</h2>
          <p>预定包间详细步骤，快速了解产品</p>
        </div>
        <ul class="first_list">
          <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/website/%E5%B1%95%E6%9E%B6.jpg" alt="">
        </ul>
        <ul class="first_list">

          <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/website/%E5%AE%A3%E4%BC%A0%E5%8D%95.jpg" alt="">
        </ul>
      </div>
    </div>
    <!-- 用户群 -->
    <div class="userTypes">
      <div class="title fadeInUp animated wow mb40 mt80">
            <h2>新店开业</h2>
            <p>2-9折优惠券随机抽</p>
          </div>
    </div>
    <!-- 功能 -->
    <div class="func pt40 ">
      <div class="w">

        <div class="title fadeInUp animated wow mb40 mt80">
          <h2>店铺介绍</h2>
          <!-- <p></p> -->
        </div>
        <div class="content">
          <div class="item_box">
            <div class="item zoomInDown animated wow">
              <div class="btn" @click="linkTo">
                <span>立即试用</span><img src="@/assets/imgs/arrow.svg" alt="">
              </div>
              <div class="bottom">
                <div class="img">
                  <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/home/fixed_icon/goCoupon.png" alt="" srcset="">
                </div>
                <div class="text">
                  <h4>最新款旋翼机，玉石60麻将</h4>
                  <!-- <p>拍摄直播 | PPT直播 | 桌面共享演示直播 | 画中画直播
                    音视频播放|电脑网页观看 | 移动网页观看 | 微信观看
                    平板观看 | 钉钉观看（开发中）
                  </p> -->
                </div>
              </div>
            </div>
            <div class="item zoomInDown animated wow">
              <div class="btn" @click="linkTo">
                <span>立即试用</span><img src="@/assets/imgs/arrow.svg" alt="">
              </div>
              <div class="bottom">

                <div class="img">
                  <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/home/fixed_icon/goCoupon.png" alt="" srcset="">
                </div>
                <div class="text">
                  <h4>直饮高端茶吧机，桶装水水质保障</h4>
                  <!-- <p>嘉宾同台 | 常见问题 | 书签笔记 | 签到公告 |
                    投票问卷 | 礼包抽奖 | 图文互动 | 在线答题
                    考试检测 | 收藏关注</p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="item_box">
            <div class="item zoomInLeft animated wow">
              <div class="btn" @click="linkTo">
                <span>立即试用</span><img src="@/assets/imgs/arrow.svg" alt="">
              </div>
              <div class="bottom">
                <div class="img">
                  <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/home/fixed_icon/goCoupon.png" alt="" srcset="">
                </div>
                <div class="text">
                  <h4>免费的精美茶饮小吃</h4>
                  <!-- <p>全平台可观看 | 本企业可观看 | 指定企业观看 | 指定个人观看指定班级观看 | 输入口令观看（开发中） -->
                  </p>
                </div>
              </div>
            </div>
            <div class="item zoomInRight animated wow">
              <div class="btn" @click="linkTo">
                <span>立即试用</span><img src="@/assets/imgs/arrow.svg" alt="">
              </div>
              <div class="bottom">

                <div class="img">
                  <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/home/fixed_icon/goCoupon.png" alt="" srcset="">
                </div>
                <div class="text">
                  <h4>符合人体学的舒适座椅，定制沙发</h4>
                  <!-- <p>聊天审核 | 智能鉴黄 | 直播防录屏 | 直播防盗播
                    高度容灾 | 内容加密 | 内容审核 | 直播监播
                  </p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="item_box">
            <div class="item zoomInLeft animated wow">
              <div class="btn" @click="linkTo">
                <span>立即试用</span><img src="@/assets/imgs/arrow.svg" alt="">
              </div>
              <div class="bottom">
                <div class="img">
                  <img src="https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/home/fixed_icon/goCoupon.png" alt="" srcset="">
                </div>
                <div class="text">
                  <h4>大功率吸烟灯，大落地窗，保证空气流通</h4>
                  <!-- <p>直播数据 | 投票数据 | 问卷数据 | 礼包数据
                    考试数据 | 视频数据 | 员工行为数据|品牌产品数据（开发中）
                  </p> -->
                </div>
              </div>
            </div>
            <div class="item zoomInRight animated wow last_item">
              <div class="bottom">
                <div class="img">
                </div>
                <div class="text">
                  <h4>更多功能，敬请期待··· ···</h4>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--使用企业  Using enterprise -->
    <div class="usingEnterprise" v-if="false">
      <div class="w">

        <div class="title fadeInUp animated wow mt100 mb40">
          <h2>众多品牌企业正在使用</h2>
        </div>
        <div class="content">
          <div class="mb20">
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (1).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (2).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (3).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (4).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (5).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (6).png" alt="">
            </div>

          </div>
          <div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (7).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (8).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (9).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (10).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (11).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (12).png" alt="">
            </div>

          </div>
          <div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (13).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (14).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (15).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (16).png" alt="">
            </div>
            <div class="item"><img src="@/assets/imgs/usingCompany/usingCompany_icon (17).png" alt="">
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 最新动态 -->
    <div class="latelyNews" v-if="false">
      <div class="w">
        <div class="title  fadeInUp animated wow mt100 mb40">
          <h2>最新动态</h2>
        </div>
        <div class="content">
          <ul>
            <li class="item  fadeInUp animated wow" v-for="item in dynamicList" :key="item.id">
              <div class="text">
                <div class="img_box">
                  <img src="@/assets/imgs/star.png" alt="">
                </div>
                <p @click="$router.push({path:'/infoDetail',query:{
        id:item.id
      }})">{{item.title}}</p>
              </div>
              <div class="date">{{item.created_at}}</div>
            </li>
          </ul>
        </div>
        <div class="more" @click="$router.push('/infoList')">更多资讯</div>
      </div>
    </div>
    <!-- 底部背景图 -->
    <div class="bg_bottom mt100">
      <div class="w content">
        <h2 class="slideInDown animated wow">金宝宝共享空间，将线下的棋牌室与线上结合起来，方便用户的场次和时段选择</h2>
        <div class="btn" @click="linkTo">
          立即试用
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import {
  WOW
} from 'wowjs'
import utils from '@/utils/utils.js'
export default {
  name: 'index',
  data() {
    return {
      // 招商页轮播图
      arr: [{img_url:'https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/website/%E5%B1%95%E6%9E%B6.jpg'}],
      // 最新企业
      companys: [
        { company_name: 0 },
        { company_name: 1 },
        // { company_name: 2 },
        // { company_name: 3 },
        // { company_name: 4 },
        // { company_name: 5 },
        // { company_name: 6 },
        // { company_name: 7 },
        // { company_name: 8 },
        // { company_name: 9 },
        ],
      // 最新动态
      dynamicList: [],
      carouselCompnay: []

    }
  },
  watch: {
    'companys'(val) {
      if (val) {
        console.log(`11`)
        this.tranlateFn(val)
      }
    }
  },
  methods: {
    linkTo() {
      // window.open('https://peixun.pinshangtong.cn/login?site=1')
      this.$router.push('/test')
    },
    getImgs() {
      utils.get('/api/merchants/advert/list', {
        position: '1'
      }).then(res => {
        if (res.err_code == 0) {
          this.arr = res.data
        }
      })
    },
    getCompany() {
      utils.get('/api/merchants/new/company/list', {
        page: 1,
        limit: 20
      }).then(res => {
        console.log('企业列表', res)
        if (res.err_code == 0) {
          // this.companys = res.data.data
          this.tranlateFn(this.companys)
        }
      })
    },
    getDynamicList() {
      utils.get('/api/merchants/new/dynamic/list', {
        page: 1,
        limit: 8
      }).then(res => {
        console.log('getDynamicList', res)
        if (res.err_code == 0) {
          this.dynamicList = res.data.data

        }
      })
    },
    tranlateFn(companys) {
      let arr = [];
      let temp = [];

      this.companys.map((item, index) => {
        temp.push(item);
        if (index % 3 == 2) {
          arr.push(temp);
          temp = [];
        }
      })
      if ((this.companys.length - 1) % 3 != 2 || this.companys.length - 1 % 3 != 0) {
        if (temp.length > 0) {
          arr.push(temp);
        }
      }
      this.carouselCompnay = arr
    },
    tranlateFn1(companys) {
      let arr = [];
      let temp = [];

      this.companys.map((item, index) => {
        temp.push(item);
        if (index % 2 == 1) {
            arr.push(temp);
          temp = [];
        }
      })

      if ((this.companys.length - 1) % 2 != 1 || this.companys.length - 1 % 2 != 0) {
        if (temp.length > 0) {
          
          arr.push(temp);
        }
      }
      this.carouselCompnay = arr
    },
  },
  created() {

    /* 获取轮播图 */
    this.getImgs()
    this.getCompany()
    this.getDynamicList()

    this.tranlateFn()
  },
  mounted() {
    /* 动画 */
    new WOW().init()

    /* 解决加载缓慢的问题 */
    //一段正则，匹配所有_min.的图片src属性
    var test = /imgs\./
    //遍历所有的图片节点
    $("img").each(function (index, obj) {
      if (test.test($(this).attr("src"))) {
        var reSrc = $(this).attr("src").replace(test, ".");
        $(this).attr("src", reSrc)
      }
    })

  }
}
</script>

<style lang="less" scoped>
// @import '../assets/css/index.css';
@import '../assets/css/animate.css';
@import '../assets/css/swiper-bundel.min.css';
@import '../assets/css/swiper.min.css';

/deep/ .el-carousel__indicator button {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #fff !important;
}

/deep/ .el-carousel__indicator.is-active button {
  width: 32px;
  height: 8px;
  border-radius: 4px;
  background: #fff;
}

.company {
  height: 60px;

  .title {
    width: 150px;
    height: 60px;
    line-height: 60px;
    background: #e7effb;
    color: #01A252;
    font-weight: bold;
    text-align: center;
    margin-right: 40px;
    flex: 2;

    font-size: 18px;
  }

  .wrap {

    display: flex;
    img{
      width: 100%;
    }
  }

  .content {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    flex: 9;

    /deep/ .el-carousel {
      width: 100%;
    }

    /deep/ .el-carousel__container {
      width: 100%;
    }

    .item {

      width: 100%;
      height: 60px;
      line-height: 60px;
      display: flex;
      align-items: center;
      margin-right: 6px;
      font-size: 16px;
      color: #666;

      .subitem {
        display: flex;
        width: 33.33%;
        align-items: center;
      }

      .circle {
        width: 8px;
        height: 8px;
        background: #01A252;
        border-radius: 50%;
        margin-right: 6px;
      }

      .img_box {
        margin-right: 6px;
      }

      .name {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

// 最新动态
/* 最新动态 start */
.latelyNews {
  .content {
    display: flex;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 45%;

        .text {
          display: flex;
          align-items: center;
          line-height: 50px;

          .img_box {
            margin-right: 6px;
          }

          p {
            width: 330px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            color: #333;
          }

        }

        .date {
          color: #666;
        }
      }

      .item:hover p,
      .item:hover .date {
        color: #01A252;
      }
    }
  }

  .more {
    width: 196px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #01A252;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 16px;
    color: #01A252;
    text-align: center;
    margin: 60px auto 0;

    font-family: PingFang SC;
    font-weight: 500;
  }

  .more:hover {
    background: #01A252;
    color: #fff;
    cursor: pointer;
  }
}

// 最新动态end
.swiper-container {
  --swiper-theme-color: #fff;
  --swiper-pagination-color: #fff;
  /* 两种都可以 */
}

.swiper-pagination .swiper-pagination-bullet {
  width: 8px;
  width: 8px;
  background: #fff;
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: 32px;
  height: 8px;
  border-radius: 4px;
}

.banner {
  /* height: 640px; */
  margin-top: 60px;
}

.banner .el-carousel-item {
  width: 100%;
}

.banner .wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 1200px; */
  /* height: 500px; */
  /* height: 100%; */
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}

.banner .el-carousel-item img {
  object-fit: contain;
}

.banner .img_box {
  margin: 0 auto;
  background-position: center;
}

.banner .btn {
  position: absolute;
  top: 75%;
  left: 20%;
  width: 143px;
  height: 40px;
  background: #4DD3F7;
  border-radius: 8px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #03489F;

}

/* 场景 */
.scenes {
  background-color: #F3F3F3;
  padding: 95px 0;

  .first_list {
    margin-bottom: 20px;
    img{
      width: 100%;
    }
  }

  ul {

    display: flex;
    font-size: 20px;
    line-height: 48px;
    justify-content: space-between;

    li {
      position: relative;
      width: 282px;
      height: 320px;
      background: #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 30px;
      line-height: 20px;

      overflow: hidden;

      h2 {
        line-height: 48px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: #333;
        text-indent: 2em;
      }

      .img_box {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80px;
        }
      }

      .img_unactive {
        display: block;
      }

      .img_active {
        display: none;
      }

      .text_box {
        margin: 5px 20px 0 20px;

        h3 {
          font-weight: 700;
          text-align: center;
          /* margin-top: 20px; */
          line-height: 48px;
          font-size: 16px;
        }
      }
    }

    li:hover .img_unactive {
      display: none;
    }

    li:hover .img_active {
      display: block;
    }

    li:after,
    li:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -282px;
      width: 0;
      height: 100%;
      width: 100%;
      z-index: -2;
      border-radius: 4px;
      // display: none;
    }

    li:hover {

      z-index: 1;
      background: transparent;

      &:after,
      &:before {
        bottom: 0;
        display: block;
      }

      h3 {
        color: #fff;

      }

      p {
        color: #fff;
        z-index: 10;
      }
    }

    li:hover:before,
    li:hover:after {
      transition: .5s;
      background: #266ED5;
      z-index: -1;
      width: 100%;
    }
  }
}

/* 用户群 */
.userTypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 950px;
  // width: 100%;
  background: rgba(7, 90, 208, 0.1) url(https://oss-mahjong-ui.oss-cn-beijing.aliyuncs.com/website/%E6%B4%BB%E5%8A%A8.png) no-repeat center;
  background-size: contain;
  .title{
    color: #fecd07;
  }
}

/* 功能 */
.func {
  background: #F3F3F3;
  padding-bottom: 100px;
}

.func .content .item_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.func .content .item_box .item {
  width: 584px;
  height: 184px;
  padding: 30px;

  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
}

/* 图片 */
.func .content .item_box .img {
  width: 64px;
  height: 64px;
  /* border: 1px dashed #ccc; */
  margin-right: 30px;
  padding: 1px;
  ;
}

.func .content .item_box .item .bottom .img img {
  width: 64px;
}

.func .content .item_box h4 {
  font-size: 16px;
  font-weight: 700;
  color: #01A252;

  margin-bottom: 10px;
}

.func .content .item_box p {
  font-size: 12px;
  color: #666;
  line-height: 32px;
}

.func .content .item_box .text {
  position: relative;
}

.func .content .item_box .btn {
  position: absolute;
  font-size: 14px;
  color: #fff;

  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #01A252;
  border-radius: 4px;

}

.func .content .item_box .item .bottom {
  margin-top: 30px;
  display: flex;
  font-size: 16px;
}

.func .content .item_box .item .bottom .more {
  margin-left: 120px;
  font-family: PingFang SC;
  font-weight: 500;
}

.func .content .item_box .item {
  position: relative;
  transition: 0.3s linear;
}

.func .content .item_box .item .btn {
  // display: none;
  position: absolute;
  top: 32px;
  right: 30px;
  transition: 1s linear;

  display: flex;
  justify-content: center;
  align-items: center;
  // img{
  //   width: 6px;
  //   height: 12px;
  // }
}

.func .content .item_box .item:hover .btn {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

}

.func .content .item_box .item:hover {
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1), -6px 0px 10px rgba(0, 0, 0, 0.1);
}

.last_item {
  color: #010920;
  text-align: center;
  font-size: 28px;
}

/* 使用企业 */
.usingEnterprise .content {
  display: flex;
  flex-direction: column;
}

.usingEnterprise .content>div {
  display: flex;
  /* justify-content: space-around; */

}

.usingEnterprise .content .item {
  width: 180px;
  height: 180px;
  /* background:pink; */
  text-align: center;
  margin: 0 11px;
  transform: scale(0.9);
  transition: all 0.5s linear;
}

.usingEnterprise .content .item:hover {
  transform: scale(1);
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1), -6px 0px 10px rgba(0, 0, 0, 0.1);
}

.usingEnterprise .content .item img {
  width: 180px;
}

/* 底部背景图 */
.bg_bottom {
  // background: url(@/assets/imgs/bg_1.png) no-repeat center;
  background: url(../assets/imgs/bg_1.png) no-repeat center;
  height: 310px;

  h2 {
    font-size: 32px;
    line-height: 48px;
    color: #fff;
    margin-bottom: 35px;

  }

  .btn {
    width: 197px;
    height: 54px;
    line-height: 54px;
    background: #5FB2FF;
    color: #002CAF;
    font-size: 16px;
    border-radius: 8px;
    text-align: center;

    font-weight: 500;
    font-family: PingFang SC;
  }

  .btn:hover {
    background: #fff;
    color: #002CAF;
    font-family: PingFang SC;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
